<template>
  <div  class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive"> <feather-icon icon="MenuIcon" size="21" /> </b-link>
      </li>

      <li class="nav-item">
        <div class="d-flex justify-content-end align-items-center ml-auto"> <!-- Add ml-auto class here -->

<b-button
        size="sm"
        variant="outline-light"
          @click="jobsModal"
      >
      <feather-icon
          icon="CalendarIcon"
          size="15"
        />
    
      
      </b-button>

</div>      </li>
    </ul>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- <dark-Toggler class="d-none d-lg-block" /> -->
      <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0 text-white">{{ userData.name }} {{ userData.surname }}</p>
            <span class="user-status " style="color:#8ABE26" v-if="userData.role == 'supermanager'">Super Manager</span>
            <span class="user-status " style="color:#8ABE26" v-else>{{ capitalizeFirstLetter(userData.role) }}</span>
          </div>

          <b-avatar size="40" variant="light-primary" badge :src="profileURL" class="badge-minimal" badge-variant="success" />
          <!-- <b-avatar size="40" variant="light-primary" badge src="" class="badge-minimal" badge-variant="success" />  -->
        </template>

        <b-dropdown-item link-class="d-flex align-items-center" @click="navigateProfile">
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-item href="https://document.showattack.tmss-repo.com/" target="_blank" link-class="d-flex align-items-center">
          <feather-icon size="16" icon="HelpCircleIcon" class="mr-50" />
          <span>Help</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>
            <b-link class="alert-link mt-5 mb-5">
              Logout
            </b-link></span
          >
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>

    <b-modal id="jobs-modal-1" cancel-variant="outline-secondary" ok-title="Ok" cancel-title="Cancel" :no-close-on-backdrop="true" centered @hidden="resetModal">

                            <template #modal-header="{ close }">
                                <span>See Jobs</span>
                            </template>
                            <div>

                              <b-card>
                                <b-overlay :show="formShow" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm" no-fade class="modal-overlay">

          <b-row>
              <b-col cols="12" xl="12" md="12" sm="12" v-if="employeeId == undefined">
                  <b-form-group label="Employee" label-for="Employee">
                      <v-select v-model="selectedEmployee" :options="users" label="full_name" input-id="customer-data" :clearable="false"> </v-select>
                  </b-form-group>
              </b-col>

              <b-col cols="12" xl="12" md="12" sm="12">
                  <b-form-group label="Data Range" label-for="Data Range">
                      <flat-pickr placeholder="Date Range" v-model="rangeDate" onClose="testClose" class="form-control" :config="config" />
                  </b-form-group>
              </b-col>

              <b-col cols="12" xl="12" md="12" sm="12">
                  <b-form-group >
                      <b-button class="btn btn-sm" @click="getAvailabilityData" variant="success">Check</b-button>

                  </b-form-group>
              </b-col>

           
          </b-row>

 
                      <b-row class="mt-2" v-if="availabilityData.length>0">

                            <span style="border: 1px solid lightgray">
                            <p class="text-danger text-center p-1">
                              Employee is assigned to {{ availabilityData.length }} jobs within the selected dates. <br>
                              <small> Please click on the job to view the details.</small>
                            </p>
                          </span>

                              <!-- <b-button class="btn btn-sm" @click="jobsModal">See Jobs</b-button> -->
                              <b-col cols="12">

                                <div class="jobTable mt-2">
                                <b-table
                                  striped
                                  hover
                                  ref="refUserListTable"
                                  class="position-relative"
                                  :items="availabilityData"
                                  filter-debounce="250"
                                  responsive
                                  :fields="tableColumns"
                                  :tbody-tr-class="(item) => isWeekend(item.date) ? 'bg-secondary text-light' : ''"
                                  @row-selected="onRowSelected"
                                  selectable
                                  select-mode="single"

                                  

                              >
                           


                                  <template #cell(date)="data">
                                      {{ dateFormat(data.value) }}
                                  </template>

                                  <template #cell(status)="data">
                                      <b-badge v-if="data.value == 'A'" pill :variant="`light-success`" class="text-capitalize">
                                          Not Worked
                                      </b-badge>

                                      <b-badge v-if="data.value == 'S'" pill :variant="`light-warning`" class="text-capitalize">
                                          Sick
                                      </b-badge>

                                      <b-badge v-if="data.value == 'H'" pill :variant="`info`" class="text-capitalize">
                                          Holiday
                                      </b-badge>

                                      <b-badge v-if="data.value == 'E'" pill :variant="`primary`" class="text-capitalize">
                                          {{ data.item.project }}
                                      </b-badge>

                                      <b-badge v-if="data.value == 'O'" pill :variant="`light-primary`" class="text-capitalize">
                                          Worked Overtime
                                      </b-badge>

                                      <b-badge v-if="data.value == 'W'" pill :variant="`danger`" class="text-capitalize">
                                          AWOL
                                      </b-badge>
                                  </template>


                              </b-table>


                              </div>


                              </b-col>
                             
                             




                        </b-row>
                        <b-row v-else>
                          <span style="border: 1px solid lightgray">
                            <p class="text-success text-center p-1">
                            Employee is available on the selected dates.

                          </p>

                          </span>
                       

                        </b-row>
                      </b-overlay>

          </b-card>


                          
                              </div>
    </b-modal>


  </div>

  
</template>

<script>
import { BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,BButton,BPopover  } from 'bootstrap-vue';
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue';
import { initialAbility } from '@/libs/acl/config';
import useJwt from '@/auth/jwt/useJwt';
import router from '@/router';
import store from '@/store';
import axiosIns from '@/libs/axios';
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";



export default {



  data() {
    
    var availabilityData = [
         {
             date: null,
             status: null,
         },
     ];

     const availabilityOptions = [
         { label: "Available", value: "A" },
         { label: "Holiday", value: "H" },
         { label: "Sick", value: "S" },
         { label: "AWOL", value: "W" },
     ];

    return {
      baseURL: store.state.app.baseURL,
      email: localStorage.getItem('email'),
      userData: JSON.parse(localStorage.getItem('userData')),
      profileURL: store.state.app.profileURL + JSON.parse(localStorage.getItem('userData')).profile,

      mainProps: {
        blank: true,
        blankColor: '#FFF',
        height: 70,

        class: 'm1',
        block: true,
      },

      selectedEmployee: null,
         availabilityData: availabilityData,
         employeeId:null,

         availabilityOptions,

         formShow: false,
         permissions: [],
         users: [],
         currentYear: new Date().getFullYear(),
         currentMonth: new Date().getMonth() + 1,
         availability: "A",

         startDate: null,
         endDate: null,
         rangeDate: null,

         fetchAvailability: [],



         totalRows: 0,

         mainProps: {
             blank: true,
             blankColor: "#777",
             width: 75,
             height: 75,
             class: "m1",
         },

         options: {
             number: {
                 numeral: true,
                 numeralThousandsGroupStyle: "thousand",
             },

             percent: {
                 numeral: true,
                 numeralPositiveOnly: true,
                 blocks: [2],
                 prefix: "% ",
                 rawValueTrimPrefix: true,
                 numeralIntegerScale: 2,
             },
         },

         config: {
             dateFormat: "d-m-Y",
             mode: "range",
             locale: { firstDayOfWeek: 1 },
         },

         perYearOptions: [2023, 2024],
         perYear: 2023,

         tableColumns: [
             { key: "date", sortable: true, class: "text-center" },
             { key: "status", sortable: true, class: "text-center" },

         ],

         teamTableColumns: [
             { key: "id", sortable: true, class: "text-center" },
             { key: "title", sortable: true, class: "text-center" },
             { key: "foreman", sortable: true, class: "text-center" },
             { key: "staff_count", sortable: true, class: "text-center" },
             { key: "day_rate", sortable: true, class: "text-center" },
             { key: "status", sortable: true, class: "text-center" },
             { key: "hotel", sortable: true, class: "text-center" },

         ],

    };
  },

  created() {

    var dateNow = new Date();
     var firstDate = moment(new Date(dateNow.getFullYear(), dateNow.getMonth(), 1)).format("DD-MM-YYYY");
     var lastDate = moment(new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 0)).format("DD-MM-YYYY");
     this.rangeDate = String(firstDate) + " to " + String(lastDate);
     //this.selectedEmployee = this.employeeId;
    //  this.getAvailabilityData();

  },

  mounted() {
    this.userDetails();

  },

  methods: {
    backRouter() {
      router.go(-1);
    },

    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    nextRouter() {
      router.go(1);
    },

    navigateProfile() {
      if (router.currentRoute.name != 'user-profile') {
        this.$router.push({ name: 'user-profile' });
      }
    },

    userDetails() {
      axiosIns
        .get(`user/${this.userData.id}`)
        .then((res) => {
          if (res.status == 200) {
            this.userData = res.data;

            if (res.data.status != 'A') {
              localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
              localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
              localStorage.removeItem('userData');
              router.push({ name: 'auth-login' });
            }
          } else {
            this.$router.push({ name: 'error-404' });
          }
        })
        .catch((error) => {
          this.$router.push({ name: 'error-404' });
        });
    },

    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

      // Remove userData from localStorage
      localStorage.removeItem('userData');

      // Reset ability
      /*
      this.$ability.update(initialAbility);
      */

      // Redirect to login page
      this.$router.push({ name: 'auth-login' });
    },

    getAvailabilityData() {
            this.formShow = true;
            this.availabilityData = [];

            var tempData = {
                startDate: null,
                endDate: null,
                userId: null,
            };

            if (this.employeeId == undefined) {
                tempData.userId = this.selectedEmployee ? this.selectedEmployee.id : null;
            } else {
                tempData.userId = this.employeeId;
            }

            if (this.rangeDate != null) {
                if (this.rangeDate.includes("to")) {
                    tempData.startDate = this.rangeDate.split(" to ")[0];
                    tempData.endDate = this.rangeDate.split(" to ")[1];
                } else {
                    tempData.startDate = this.rangeDate;
                }
            }

            axiosIns
          //.get("user", { params: queryParams })
          .get('availabilityData',  { params: tempData } )
          .then((response) => {
                    
                    this.users = response.data.userList;
                    if (this.selectedEmployee == null) {
                        // if (this.employeeId == undefined) {
                        this.selectedEmployee = this.users[0];


                        // } else {
                        //   this.selectedEmployee = this.users.find((user) => (user.id = this.employeeId));
                        // }
                    }

                    response.data.data.forEach(element => {
                      if(element.status != 'A'){
                        this.availabilityData.push(element)
                      }
                    });


                    this.formShow = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.formShow = false;

                    this.$swal({
                        title: "Error",
                        text: "Failed to create report. Please try again or contact support.",
                        icon: "error",
                        showCancelButton: false,
                        confirmButtonText: "OK",
                    });
                });
        },

        jobsModal(){
        this.getAvailabilityData();
        this.$bvModal.show('jobs-modal-1');


        },

        resetModal(){

          this.availabilityData = [];

          var dateNow = new Date();
          var firstDate = moment(new Date(dateNow.getFullYear(), dateNow.getMonth(), 1)).format("DD-MM-YYYY");
          var lastDate = moment(new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 0)).format("DD-MM-YYYY");
          this.rangeDate = String(firstDate) + " to " + String(lastDate);
          this.selectedEmployee = this.users[0];


          },



        onRowSelected(item) {
          

        if(item[0].projectId>0){
          router.push({ name: 'event-view', params: { id: item[0].projectId } });
          this.$bvModal.hide('jobs-modal-1'); // 
        }


        
        },



        dateFormat(val) {
            moment.locale("en-US");

            return moment(val, "DD-MM-YYYY").format("DD/MM/YYYY");
        },

        isWeekend(dateStr) {
            if(dateStr){

                const [day, month, year] = dateStr.split('-');

                // Create a new date object using the components in "Y-m-d" format
                const parsedDate = new Date(`${year}-${month}-${day}`);

                // Get the day of the week (0 for Sunday, 1 for Monday, and so on)
                const dayOfWeek = parsedDate.getDay();

                // Check if the day is Saturday (6) or Sunday (0), which are considered weekends
                return dayOfWeek === 6 || dayOfWeek === 0;


            }
            
        },


  },

  computed: {


  },

  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BButton,
    BPopover,
    flatPickr,
    vSelect,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
};
</script>


<style lang="scss">

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>


<style scoped>

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.jobTable{
  height:500px;
  overflow-x: scroll;
}

</style>
