<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT © {{ new Date().getFullYear() }}
      <b-link class="ml-25" href="https://www.systemstailormade.com/" target="_blank">
        <img src="https://www.systemstailormade.com/hosted/images/e7/49fb62f26e4660b74b9b0fbdbfd163/Black-Cat-No-BG.png" height="30px" alt="" />
        Systems Tailor Made</b-link
      >
      <span class="d-none d-sm-inline-block">, All rights Reserved</span>
    </span>

    <!-- <b-link class="ml-25" href="https://www.systemstailormade.com/" target="_blank">
      <span class="float-md-right d-none d-md-block"
        >Systems Tailor Made
        <feather-icon icon="FileTextIcon" size="16" class="text-success stroke-current" />

        <img src="https://www.systemstailormade.com/hosted/images/e7/49fb62f26e4660b74b9b0fbdbfd163/Black-Cat-No-BG.png" height="30px" alt="" />
      </span>
    </b-link> -->

    <b-link class="ml-5 mr-5" href="https://document.showattack.tmss-repo.com/" target="_blank">
      <span class="float-md-right d-none d-md-block">
        <feather-icon icon="HelpCircleIcon" size="32" class="text-primary stroke-current" />
      </span>
    </b-link>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue';

export default {
  components: {
    BLink,
  },
};
</script>
